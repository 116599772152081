import React, { useState } from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Hero from "../components/hero"
import SEO from "../components/seo"
import ReadingMode from "../components/reading-mode"
import { tryGetBlogReadingMode } from "../utils/local-storage"

export default ({ data }) => {
  const post = data.markdownRemark
  const [readingMode, setReadingMode] = useState(tryGetBlogReadingMode())

  const readingModeSelected = (readingMode) => {
    setReadingMode(readingMode)
  }

  return (
    <Layout>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.summary}
      />

      <div>
        <Hero
          title={post.frontmatter.title}
          subtitle={`${post.frontmatter.date} - ${post.frontmatter.author}`}
          subtitleSize="6"
        />
        <section className="section blog-post-header">
          <ReadingMode
            readingMode={readingMode}
            onReadingModeSelected={readingModeSelected}
          />
        </section>

        <section className="section blog-post">
          <div
            className={`blog-post-content ${
              readingMode === "column" ? "reading-mode-column" : ""
            }`}
          >
            <div dangerouslySetInnerHTML={{ __html: post.html }} />
          </div>
          <nav className="level blog-item-footer">
            <p className="level-item has-text-centered">
              <Link to="/blog">Back to blog index</Link>
            </p>
          </nav>
        </section>
      </div>
    </Layout>
  )
}
export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        author
        summary
      }
    }
  }
`
