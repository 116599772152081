import React from "react"
import { trySetBlogReadingMode } from "../utils/local-storage"
import FormatLeftIcon from "../images/svg/format_align_left.svg"
import FormatCenterIcon from "../images/svg/format_align_center.svg"

class ReadingMode extends React.Component {
  state = {
    blogReadingMode: this.props.readingMode,
  }

  changeBlogReadingMode = (e, readingMode) => {
    e.preventDefault()
    this.setState({ blogReadingMode: readingMode })
    trySetBlogReadingMode(readingMode)
    this.props.onReadingModeSelected(readingMode)
  }

  render() {
    return (
      <>
        <div className="field reading-mode has-addons">
          <p className="control">
            <button
              className={`button is-small ${
                this.state.blogReadingMode === "wide" ? "is-selected" : ""
              }`}
              onClick={e => this.changeBlogReadingMode(e, "wide")}
            >
              <span className="icon is-small">
                <FormatLeftIcon />
              </span>
              <span>wide</span>
            </button>
          </p>
          <p className="control">
            <button
              className={`button is-small ${
                this.state.blogReadingMode === "column" ? "is-selected" : ""
              }`}
              onClick={e => this.changeBlogReadingMode(e, "column")}
            >
              <span className="icon is-small">
                <FormatCenterIcon />
              </span>
              <span>column</span>
            </button>
          </p>
        </div>
      </>
    )
  }
}

export default ReadingMode
